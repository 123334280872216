<div class="m-3">
    <h4>Khoản thu được gán cho <strong>{{data.full_name}}</strong></h4>
    <div>

        <div *ngIf="dataList.length == 0" class="text-center">
            <app-nodata msg="Chưa có khoản thu nào được gán"></app-nodata>
        </div>
        <div *ngIf="dataList.length > 0" style="
            max-height: 80vh;" class="qbs_scroll">
            <table class="table table-head-purple table-hover" id="datatable">
                <thead class="thead-default thead-lg">
                    <tr>
                        <th style="width: 50px; text-align: center;">#</th>
                        <th>Code</th>
                        <th>Khoản thu</th>
                        <th>Giá trị</th>
                        <th class="text-center">Tần xuất</th>
                        <th style="text-align: center">Kỳ thu</th>
                        <th style="text-align: center">Trạng thái</th>
                        <!-- <th class="text-center">Phút tính sơm</th>
                        <th class="text-center">Phút tính muộn</th> -->
                        <th class="no-sort"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList; let i = index" [ngClass]="{'row-uncheck': !item.temp_checked}">
                        <td>
                            <a class="avatar-img-list" href="javascript:;">
                                <img class="img-circle mt-1" src="./assets/img/icons/finance.png" alt="image" width="30" />
                            </a>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{appService.currentUser.bidv_merchantId}}{{item.finance_student_id}}</div>
                        </td>
                        <td>
                            <div style="font-size: 18px;">{{item.name}}</div>
                            <ng-container *ngIf="item.finance_type == 'dot'">
                                <table style="width: 100%;">
                                    <tr *ngFor="let jtem of item.finance_content_json; let i = index">
                                        <td style="padding: 3px 6px;">
                                            <span class="material-icons font-16 text-secondary"> noise_control_off </span>{{jtem.label}}
                                        </td>
                                        <td style="padding: 3px 6px; text-align: right; vertical-align: middle;">
                                            <span class="ml-4">{{jtem.value | qbscurrency}}</span>
                                        </td>
                                    </tr>
                                </table>
                                <!-- <span class="badge badge-secondary mr-2" *ngFor="let jtem of item.finance_content_json; let i = index"> {{jtem.label}}</span> -->
                            </ng-container>
                        </td>
                        <td class="text-right">
                            <!-- <div style="font-size: 18px; font-weight: bold;">{{item.finance_cate_value| qbscurrency}}</div> -->
                            <div class="fin-value-cover">
                                <input type="text" [(ngModel)]="item.finance_cate_value" [name]="'finance_cate_value'+i" currencyMask class="fin-value-input" maxlength="10" />
                                <button *ngIf="item.temp_checked" (click)="updateFinanceStudent(item)" class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Cập nhật">
                                    <span *ngIf="!isLoading" class="material-icons"> done </span>
                                    <span *ngIf="isLoading" class="spinner-border"></span>
                                </button>
                            </div>
                        </td>
                        <td style="text-align: center">{{item.finance_frequency}}</td>
                        <td style="text-align: center">
                            <div *ngIf="item.finance_frequency == 'Tháng'">
                                {{item.period_time_str}}
                            </div>
                        </td>
                        <td>
                            <!-- {{item.status}} -->
                            <button *ngIf="item.status == 0" class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                                <span class="btn-label"><img style="width: 23px;" src="assets\img\icons\check-list.png" alt=""></span>
                                Chưa gửi
                            </button>
                            <button *ngIf="item.status == 1" class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                                <span class="btn-label"> <img style="width: 23px;" src="assets\img\icons\check-list-send.png" alt=""></span>
                                Đã gửi
                            </button>
                            <button *ngIf="item.status == 2" class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                                <span class="btn-label"> <img style="width: 23px;" src="assets\img\icons\check-list-mark.png" alt=""></span>
                                Đã thanh toán
                            </button>

                            <!-- <img *ngIf="item.status == 1" style="width: 23px;" src="assets\img\icons\check-list-send.png" alt="">
                            <img *ngIf="item.status == 2" style="width: 23px;" src="assets\img\icons\check-list-mark.png" alt=""> -->
                        </td>
                        <td>
                            <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox" [disabled]="item.finance_student_id == null"  [(ngModel)]="item.temp_checked">
                                <span class="input-span"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isLoading" class="text-center m-2">
            <span class="spinner-border spinner-lg"></span>
        </div>
        <div class="mt-4 d-flex justify-content-end">
            <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Lưu thay đổi
            </button>
            <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
            </button>
        </div>
    </div>
</div>