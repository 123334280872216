import { DebtCustomerComponent } from './pages/debt-customer/debt-customer.component';
import { BrandComponent } from './pages/brand/brand.component';
import { PromotionAddComponent } from './pages/promotion/promotion-add/promotion-add.component';
import { CustomerCateComponent } from './pages/customer-cate/customer-cate.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { StockinCartComponent } from './pages/stockin/stockin-cart/stockin-cart.component';
import { StockinComponent } from './pages/stockin/stockin.component';
import { ReportComponent } from './pages/report/report.component';
import { CategoryComponent } from './pages/category/category.component';
import { ProductComponent } from './pages/product/product.component';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';
import { SaleComponent } from './pages/sale/sale.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './/layouts/layout.component';

import { HomeComponent } from './pages/home/home.component';
import { Dashboard4Component } from './pages/dashboard-4/dashboard-4.component';




import { TablesComponent } from './pages/tables/tables.component';

import { ChartjsComponent } from './pages/charts/chartjs/chartjs.component';
import { MorrisChartComponent } from './pages/charts/morris-chart/morris-chart.component';
import { PeityChartComponent } from './pages/charts/peity-chart/peity-chart.component';
import { SparklineChartComponent } from './pages/charts/sparkline-chart/sparkline-chart.component';



import { BlogListComponent } from './pages/blog/blog-list/blog-list.component';
import { ArticleComponent } from './pages/blog/article/article.component';


import { SearchComponent } from './pages/search/search.component';

import { LoginComponent } from './pages/login/login.component';
import { Login3Component } from './pages/login-3/login-3.component';
import { LockscreenComponent } from './pages/lockscreen/lockscreen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ProfileComponent } from './pages/profile/profile.component';
import {FormsModule} from "@angular/forms";
import { CustomerComponent } from './pages/customer/customer.component';
import { Report2Component } from './pages/report2/report2.component';
import { OrderComponent } from './pages/order/order.component';
import { SettingComponent } from './pages/setting/setting.component';
import { DebtComponent } from './pages/debt/debt.component';
import { ServicedeskComponent } from './pages/servicedesk/servicedesk.component';
import { ServicedeskpriceComponent } from './components/servicedeskprice/servicedeskprice.component';
import { UnitComponent } from './components/unit/unit.component';
import { ReportMonthComponent } from './pages/report-month/report-month.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { InventoryListComponent } from './pages/inventory-list/inventory-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StudentComponent } from './pages/student/student.component';
import { TeacherComponent } from './pages/teacher/teacher.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ClasssComponent } from './pages/classs/classs.component';
import { FacultyComponent } from './pages/category/faculty/faculty.component';
import { SubjectsComponent } from './pages/subjects/subjects.component';
import { ScholasticComponent } from './pages/scholastic/scholastic.component';
import { TeachingAssignmentComponent } from './pages/teaching-assignment/teaching-assignment.component';
import { CheckinComponent } from './pages/checkin/checkin.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { AssessmentSettingComponent } from './pages/assessment/assessment-setting/assessment-setting.component';
import { SchedulesComponent } from './pages/schedules/schedules.component';
import { TeachingSchedulesComponent } from './pages/teaching-schedules/teaching-schedules.component';
import { DiscussionComponent } from './pages/discussion/discussion.component';
import { CameraComponent } from './pages/camera/camera.component';
import { CameraStudentComponent } from './pages/camera-student/camera-student.component';
import { CheckinReportComponent } from './pages/checkin-report/checkin-report.component';
import { StudentHomeComponent } from './student/student-home/student-home.component';
import { StudentMessengerComponent } from './student/student-messenger/student-messenger.component';
import { FinanceCategoryComponent } from './pages/finance/finance-category/finance-category.component';
import { FinanceAssignmentComponent } from './pages/finance/finance-assignment/finance-assignment.component';
import { FinanceReportComponent } from './pages/finance/finance-report/finance-report.component';
import { CheckinUnknownComponent } from './pages/checkin-unknown/checkin-unknown.component';
import { CheckinTeacherReportComponent } from './pages/checkin-teacher-report/checkin-teacher-report.component';
import { ApplicationComponent } from './pages/application/application.component';
import { ClassHistoryComponent } from './pages/class-history/class-history.component';
import { HealthCateComponent } from './pages/health/health-cate/health-cate.component';
import { HealthRecordComponent } from './pages/health/health-record/health-record.component';
import { HealthReportComponent } from './pages/health/health-report/health-report.component';
import { AssessmentReportComponent } from './pages/assessment/assessment-report/assessment-report.component';
import { AssessmentStudentComponent } from './pages/assessment/assessment-student/assessment-student.component';
import { AssessmentPeriodComponent } from './pages/assessment/assessment-period/assessment-period.component';
import { MealComponent } from './pages/meal/meal.component';
import { MealReportComponent } from './pages/meal/meal-report/meal-report.component';
import { MealTimeComponent } from './pages/meal/meal-time/meal-time.component';
import { ClassTimeComponent } from './pages/classs/class-time/class-time.component';
import { CategorySloganComponent } from './pages/category/category-slogan/category-slogan.component';
import { HolidayComponent } from './pages/category/holiday/holiday.component';
import { MealReportMonthComponent } from './pages/meal/meal-report-month/meal-report-month.component';
import { FinanceBankingComponent } from './pages/finance/finance-banking/finance-banking.component';
import { EquipmentCateComponent } from './pages/equipment/equipment-cate/equipment-cate.component';
import { EquipmentComponent } from './pages/equipment/equipment/equipment.component';
import { SchedulesDailyComponent } from './pages/teaching-schedules/schedules-daily/schedules-daily.component';
import { SchedulesReportComponent } from './pages/teaching-schedules/schedules-report/schedules-report.component';
import { KhoicosoComponent } from './pages/category/khoicoso/khoicoso.component';
import { OnlineSchduleComponent } from './pages/category/online-schdule/online-schdule.component';
import { CategoryCheckinTimeComponent } from './pages/category/category-checkin-time/category-checkin-time.component';
import { TaskComponent } from './pages/task/task.component';

const routes: Routes = [
    {path: '', redirectTo: 'index', pathMatch: 'full'},
    {
        
        "path": "",
        "component": LayoutComponent,
        "children": [
            {
                path: "index",
                component: HomeComponent
            },
            {
                path: "sale",
                component: SaleComponent
            },
            {
                path: "home",
                component: HomeComponent
            },
            {
                path: "student",
                component: StudentComponent
            },
            {
                path: "teacher",
                component: TeacherComponent
            },
            {
                path: "settings",
                component: SettingsComponent
            },
            {
                path: "class",
                component: ClasssComponent
            },
            {
                path: "teachingAssignment",
                component: TeachingAssignmentComponent
            },
            {
                path: "faculty",
                component: FacultyComponent
            },
            {
                path: "subjects",
                component: SubjectsComponent
            },
            {
                path: "scholastic",
                component: ScholasticComponent
            },
            {
                path: "checkIn",
                component: CheckinComponent
            },
            {
                path: "checkIn-report",
                component: CheckinReportComponent
            },
            {
                path: "assessment",
                component: AssessmentComponent
            },
            {
                path: "assessment-setting",
                component: AssessmentSettingComponent
            },
            {
                path: "schedules",
                component: SchedulesComponent
            },
            {
                path: "teaching-schedule",
                component: TeachingSchedulesComponent
            },
            {
                path: "schedule-daily",
                component: SchedulesDailyComponent
            },
            {
                path: "schedule-report",
                component: SchedulesReportComponent
            },
            {
                path: "discussion",
                component: DiscussionComponent
            },
            {
                path: "category-checkin-time",
                component: CategoryCheckinTimeComponent
            },
            {
                path: "online-schedule",
                component: OnlineSchduleComponent
            },
            {
                path: "health-cate",
                component: HealthCateComponent
            },
            {
                path: "health-record",
                component: HealthRecordComponent
            },
            {
                path: "schoolprofile",
                component: DiscussionComponent
            },
            {
                path: "health-report",
                component: HealthReportComponent
            },
            {
                path: "assessment-report",
                component: AssessmentReportComponent
            },
            {
                path: "assessment-student",
                component: AssessmentStudentComponent
            },
            {
                path: "khoicoso",
                component: KhoicosoComponent
            },
            {
                path: "task",
                component: TaskComponent
            },
            {
                path: "transcript",
                component: DiscussionComponent
            },
            {
                path: "camera",
                component: CameraComponent
            },
            {
                path: "camera-student",
                component: CameraStudentComponent
            },
            {
                path: "student-overview",
                component: StudentHomeComponent
            },
            {
                path: "student-messenger",
                component: StudentMessengerComponent
            },
            {
                path: "finance-cate",
                component: FinanceCategoryComponent
            },
            {
                path: "finance-assignment",
                component: FinanceAssignmentComponent
            },
            {
                path: "finance-report",
                component: FinanceReportComponent
            },
            {
                path: "finance-banking",
                component: FinanceBankingComponent
            },
            {
                path: "checkIn-unknown",
                component: CheckinUnknownComponent
            },
            {
                path: "checkin-teacher-report",
                component: CheckinTeacherReportComponent
            },
            {
                path: "application",
                component: ApplicationComponent
            },
            {
                path: "class-history",
                component: ClassHistoryComponent
            },
            {
                path: "assessment-period",
                component: AssessmentPeriodComponent
            },
            {
                path: "meal",
                component: MealComponent
            },
            {
                path: "meal-report",
                component: MealReportComponent
            },
            {
                path: "meal-report-month",
                component: MealReportMonthComponent
            },
            {
                path: "meal-time",
                component: MealTimeComponent
            },
            {
                path: "class-time",
                component: ClassTimeComponent
            },
            {
                path: "slogan",
                component: CategorySloganComponent
            },
            {
                path: "holiday",
                component: HolidayComponent
            },
            {
                path: "equipment",
                component: EquipmentComponent
            },
            {
                path: "equipment-cate",
                component: EquipmentCateComponent
            },
            {
                path: "customer",
                component: CustomerComponent
            },
            {
                path: "customer-cate",
                component: CustomerCateComponent
            },
            
            {
                path: "category",
                component: CategoryComponent
            },
            {
                path: "report",
                component: ReportComponent
            },
            {
                path: "report2",
                component: Report2Component
            },
            {
                path: "report-month",
                component: ReportMonthComponent
            },
            
            {
                path: "profile",
                component: ProfileComponent
            },
            {
                path: "setting",
                component: SettingComponent
            },
            {
                path: "brand",
                component: BrandComponent
            },
            {
                path: "promotion",
                component: PromotionComponent
            },
            {
                path: "promotion-detail",
                component: PromotionAddComponent
            },
            {
                path: "debt",
                component: DebtComponent
            },
            {
                path: "debt-customer",
                component: DebtCustomerComponent
            },
            {
                path: "servicedesk",
                component: ServicedeskComponent
            },
            {
                path: "deskprice",
                component: ServicedeskpriceComponent
            },
            {
                path: "unit",
                component: UnitComponent
            },

            {
                path: "dashboard_4",
                component: Dashboard4Component
            },
        
            {
                path: "tables/basic",
                component: TablesComponent
            },
            {
                path: "charts/chartjs",
                component: ChartjsComponent
            },
            {
                path: "charts/charts_morris",
                component: MorrisChartComponent
            },
            {
                path: "charts/charts_peity",
                component: PeityChartComponent
            },
            {
                path: "charts/charts_sparkline",
                component: SparklineChartComponent
            },

            {
                path: "blog/blog_list",
                component: BlogListComponent
            },
            {
                path: "blog/article",
                component: ArticleComponent
            },
                       
            {
                "path": "search",
                "component": SearchComponent
            },
          
        ]
    },
    {
        "path": "login",
        "component": Login3Component
    },
    {
        "path": "**",
        "redirectTo": "error_404",
        "pathMatch": "full"
    },
];

@NgModule({
  declarations: [
 
    Dashboard4Component,
    TablesComponent,
    ChartjsComponent,
    MorrisChartComponent,
    PeityChartComponent,
    SparklineChartComponent,
    BlogListComponent,
    ArticleComponent,
    SearchComponent,
    LoginComponent,
    LockscreenComponent,
    ProfileComponent,
  ],
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ,FormsModule],
  exports: [ 
    RouterModule,
  ]
})

export class AppRoutingModule { }
