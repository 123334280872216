<div class="row mb-2 mt-2 justify-content-between">
    <div class="p-0 d-flex justify-content-start align-items-center">

        <mat-button-toggle-group [value]="type_range" #group="matButtonToggleGroup" (change)="onValChange(group.value)" appearance="" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="day">
                <span class="material-icons mr-1"> event </span> Lịch ngày</mat-button-toggle>
            <mat-button-toggle value="week">
                <span class="material-icons mr-1"> date_range </span> Lịch tuần</mat-button-toggle>
            <mat-button-toggle value="month">
                <span class="material-icons mr-1"> calendar_month </span> Lịch tháng</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-form-field appearance="fill" style="width: 120px;" class=" ml-4 mr-3">
            <mat-label>Tổ bộ môn</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                    {{item.name}} ({{item.teacherSum}})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 170px;" class="mr-3">
            <mat-label>Giáo viên</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.teacher_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of teacher_list" [value]="item.code" [disabled]="false">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 120px; " class="mr-3">
            <mat-label>Lớp</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)" name="class_code">
                <mat-option value="" *ngIf="appService.checkFullPermission()">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFullPermission() && item.isAvailable == 0">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>






    </div>

    <div class=" p-0 d-flex justify-content-end align-items-center">
        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
        <button class="ml-2 btn btn-lg btn-labeled btn-labeled-left btn-icon" (click)="confirmDownloadExcel()">
            <span class="btn-label">
                <img class="icon-image-btn" src="./assets/img/icons/excel.png" alt="image" width="20" />
            </span>
            Xuất Excel
        </button>
        <!-- <div ngbDropdown class="d-inline-block ml-2">
            <button class="btn btn-2 btn-primary btn-labeled btn-labeled-left btn-icon  mr-2" ngbDropdownToggle>
                <span class="btn-label"><span class="material-icons"> menu </span></span>
                Báo cáo
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="selectReport(1)" class="text-primary">
                    <span class="material-icons mr-1"> event </span>
                    Lịch ngày
                </button>
                <button ngbDropdownItem (click)="selectReport(2)" class="text-primary">
                    <span class="material-icons mr-1"> assignment_ind </span>
                    Báo cáo theo giáo viên
                </button>
            </div>
        </div> -->
    </div>

</div>
<div>
    <ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

        <li class="nav-item" *ngFor="let item of dateRange; let i = index">
            <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
                <div>{{item.dayOfWeek}}</div>
                <div style="font-size: large;">{{item.ddmm}}</div>
                <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
            </a>
        </li>

    </ul>
</div>
<ng-container *ngIf="type_report==1">
    <div class="row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">TT</th>
                    <th style="width: 60px;">Thứ</th>
                    <th style="width: 105px;">Ngày</th>
                    <th style="width: 110px;">Thời gian</th>
                    <th style="width: 9px;">T.Lượng</th>
                    <th>Giáo viên</th>
                    <th>Lớp</th>
                    <th>Môn</th>
                    <th>Lịch</th>
                    <th style="max-width: 200px;">Ghi chú</th>
                    <th style="width: 170px; text-align: center;">Checkin</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.dataListDetail; let i = index" [ngClass]="item.checkin_time == undefined ? 'row_title' : 'row_item'">
                    <td class="stt_td action-cover" style=" text-align: center; padding: 0.5rem;">
                        <div *ngIf="item.id!= undefined">
                            <ng-container *ngIf="!isLoading">
                                <a [ngClass]="appService.checkFullPermission() ? 'avatar-can-hide' : ''" class="avatar-img-list" style="width: 30px; border-radius: 50%;  height: 30px;" href="javascript:;" [style.background-image]="appService.showImageTeacher(item.image_url)"></a>
                                <div *ngIf="appService.checkFullPermission()" class="item-action">
                                    <div ngbDropdown class="d-inline-block">
                                        <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" ngbDropdownToggle>
                                            <span class="material-icons">
                                                menu
                                            </span>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <button ngbDropdownItem (click)="editScheduleDetail(item, 'edit')">
                                                <span class="material-icons mr-1"> edit_square </span>
                                                Sửa lịch
                                            </button>
                                            <button ngbDropdownItem (click)="editScheduleDetail(item, 'copy')">
                                                <span class="material-icons mr-1"> content_copy </span>
                                                Copy lịch
                                            </button>
                                            <button ngbDropdownItem (click)="popupDelete(item,i)">
                                            <span class="material-icons mr-1"> cancel </span> Xóa lịch
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isLoading" class="text-left">
                                <span class="spinner-border text-primary" style="height: 30px; width: 30px; border-width: 2px;"> </span>
                            </div>
                        </div>
                    </td>
                    <td>{{item.checkin_day}}</td>
                    <td>{{item.checkin_date}}</td>
                    <td>
                        <ng-container *ngIf="item.checkin_start!= item.checkin_start_origin || item.checkin_end!= item.checkin_end_origin">
                            <div class="text-warning" placement="top" [ngbTooltip]="item.checkin_start_origin +' - '+item.checkin_end_origin">
                                {{item.checkin_time}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.checkin_start == item.checkin_start_origin && item.checkin_end == item.checkin_end_origin">
                            {{item.checkin_time}}
                        </ng-container>
                    </td>
                    <td class="text-center">{{item.checkin_duration}}</td>
                    <td>
                        <div *ngIf="item.id!= undefined">
                            <ng-container *ngIf="item.isAvailbale == 0">
                                <button placement="top" ngbTooltip="Không thể đổi giáo viên" class="btn btn-outline-secondary btn-icon mr-2">
                                <span class="text-muted mr-1">
                                    <span class="material-icons"> assignment_ind </span>
                                </span>
                                <span>{{item.teacher_name}}</span>
                            </button>
                            </ng-container>
                            <ng-container *ngIf="item.isAvailbale == 1">
                                <button (click)="openSelectTeacher(item,i)" [ngbTooltip]="item.teacher_code_origin == item.teacher_code ? 'Đổi giáo viên' : 'Đã đổi giáo viên'" placement="top" class="btn btn-outline-primary btn-icon mr-2">
                                    <span class="btn-label mr-1 "
                                        [ngClass]="item.teacher_code_origin == item.teacher_code ? '' : 'text-warning'">
                                        <span class="material-icons"> assignment_ind </span>
                                    </span>
                                    <span>{{item.teacher_name}}</span>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                    <td>{{item.class_name}}</td>
                    <td>{{item.subject_name}}</td>
                    <td>{{item.checkin_name}}</td>
                    <td>
                        <ng-container *ngIf="item.id!= undefined">
                            <div class="note-cover">
                                <input type="text" [(ngModel)]="item.note" [name]="'note_'+i" class="note-input">
                                <button class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Lưu ghi chú" (click)="saveNoteOne(item)">
                                <span *ngIf="!isLoading_note" class="material-icons"> done </span>
                                <span *ngIf="isLoading_note" class="spinner-border"></span>
                            </button>
                            </div>
                        </ng-container>
                    </td>
                    <td class="checkin_status_cover">
                        <ng-container *ngIf="item.id != undefined">
                            <button class="btn btn-icon-only btn-sm mr-1" [ngClass]="item.checkin_status == 1 ? 'btn-outline-primary':'btn-outline-secondary'" placement="top" ngbTooltip="Đúng giờ">
                            <span class="material-icons"> check_circle </span>
                        </button>
                            <button class="btn btn-icon-only btn-sm mr-1" [ngClass]="item.checkin_status == 2 ? 'btn-outline-warning':'btn-outline-secondary'" placement="top" ngbTooltip="Muộn">
                            <span class="material-icons"> report </span>
                        </button>
                            <button class="btn btn-icon-only btn-sm mr-1" [ngClass]="item.checkin_status == 3 ? 'btn-outline-danger':'btn-outline-secondary'" placement="top" ngbTooltip="Vắng">
                            <span class="material-icons"> report </span>
                        </button>

                            <button class="btn btn-icon-only btn-sm ml-4" [ngClass]="item.checkinList_times.length > 0 ? 'btn-outline-info':'btn-outline-secondary'" placement="top" [ngbTooltip]="item.checkinList_times.length > 0 ? item.checkinList_times.length + ' chi tiết':'Không có dữ liệu điểm danh'"
                                (click)="showCheckinDetail(item)">
                            <span class="material-icons"> list </span>
                        </button>


                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</ng-container>
<ng-container *ngIf="type_report==2">
    <div class="row mb-2">
        <div class="col-12 d-flex justify-content-between">
            <div>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-primary"> check_circle </span>
                    </span>
                    Đúng giờ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-warning"> report </span>
                    </span>
                    Muộn/trễ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-danger"> report </span>
                    </span>
                    Vắng
                </button>
            </div>
            <div>

            </div>
        </div>
    </div>
</ng-container>



<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<app-nodata *ngIf="!isLoading && this.dataListDetail.length ==0" msg="Không có dữ liệu"></app-nodata>