import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class UtilityService {



  constructor(
    private appService: AppService,

  ) { }
  public getFirstFullDateStrFromDate(date: Date): string {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return "01" + "/" + month + "/" + date.getFullYear();
  }
  private timeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  public isWithinTimeRange(startTime, endTime, newStartTime, newEndTime) {
    const start = this.timeToMinutes(startTime);
    const end =this. timeToMinutes(endTime);
    const newStart = this.timeToMinutes(newStartTime);
    const newEnd = this.timeToMinutes(newEndTime);

    return newStart >= start && newStart <= end && newStart <= newEnd && newEnd <= end;
  }
  public calculateDuration(startTime, endTime) {
    const start = this.timeToMinutes(startTime);
    const end = this.timeToMinutes(endTime);
    const durationInMinutes = end - start;

    const hours = Math.floor(Math.abs(durationInMinutes) / 60);
    const minutes = Math.abs(durationInMinutes) % 60;

    // Kiểm tra nếu durationInMinutes âm thì thêm dấu "-" vào kết quả
    const sign = durationInMinutes < 0 ? '-' : '';

    return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}
  public getTimeDiff(startTime, endTime) {
    const start = this.timeToMinutes(startTime);
    const end = this.timeToMinutes(endTime);
    const durationInMinutes = end - start;

    return durationInMinutes;
}
  public getCapbyKhoi(khoi_code: string): string {
    console.log(khoi_code);
    let th = ["khoi_1", "khoi_2", "khoi_3", "khoi_4", "khoi_5"];
    let thcs = ["khoi_6", "khoi_7", "khoi_8", "khoi_9"];
    let thpt = ["khoi_10", "khoi_11", "khoi_12"];
    if (th.indexOf(khoi_code) > -1)
      return "TH";
    if (thcs.indexOf(khoi_code) > -1)
      return "THCS";
    if (thpt.indexOf(khoi_code) > -1)
      return "THPT";
  }
  public getDayDiff(dateStart: Date, dateEnd: Date): number {
    if(dateStart == null || dateEnd == null) return 0;
    // Kiểm tra nếu data là chuỗi
    if (typeof dateStart === 'string') {
      dateStart = new Date(dateStart);
    }
    if (typeof dateEnd === 'string') {
      dateEnd = new Date(dateEnd);
    }

    // Tính toán thời gian (mili giây) giữa hai ngày
    const timeDifference = Math.abs(dateEnd.getTime() - dateStart.getTime());

    // Chuyển đổi mili giây thành ngày
    const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return dayDifference;
  }
  public getFirstDateFromDate(date: Date): Date {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return this.getDateFromStr("01" + "/" + month + "/" + date.getFullYear());
  }

  public getLastFullDateStrFromDate(date: Date): string {
    let day_temp = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return this.getFullDateStrFromDate(day_temp);
  }
  public getLastDateFromDate(date: Date): Date {
    let day_temp = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return day_temp;
  }

  public checkSmallThanToDay(dateStr: String): boolean {
    var result = false;
    var temps = this.dateStrToDate(dateStr);
    var today = new Date();
    if (temps <= today) result = true;
    return result;
  }
  public checkThongTu(thongtu): string {
    var list_old = 'khoi_5,khoi_9'.split(",");
    if (list_old.includes(thongtu)) {
      return 'old';
    } else {
      return 'new';
    }
  }
  public getDayOfDateYYYYmmddStr(dateStr: String): any {
    var result = -1;
    var temps = dateStr.split("-");
    if (temps.length == 3) {
      var dt = parseInt(temps[2]);
      var mon = parseInt(temps[1]);
      var yr = parseInt(temps[0]);
      var date_temp = new Date(yr, mon - 1, dt);
    }
    return date_temp.getDay();
  }
  public getWeekNameByWeekCode(checkin_weeks: String): any {
    var result = [];
    if (checkin_weeks != '') {
      var temps = checkin_weeks.split("w");
      temps.forEach(element => {
        this.appService.weeks.forEach(element_week => {
          if (element == element_week.code) {
            result.push(element_week.value);
          }
        });
      });
    }
    return result;
  }
  public getDateFromStr(dateStr: String): Date {
    console.log(dateStr);
    if (dateStr == null || typeof dateStr == 'undefined') {

    } else {
      var temps = dateStr.split("/");
      if (temps.length == 3) {
        var dt = parseInt(temps[0]);
        var mon = parseInt(temps[1]);
        var yr = parseInt(temps[2]);
        return new Date(yr, mon - 1, dt);
      }
    }
    return null;
  }
  public prepareClassTimeData(item: any): any {
    let result = [];
    if (item.class_time_ids != null) {
      let class_time_ids = item.class_time_ids.split(",");
      let class_time_names = item.class_time_names.split(",");
      let class_time_weeks = item.class_time_weeks.split(",");
      let class_times = item.class_times.split(",");
      class_time_ids.forEach((element, i) => {
        result.push({
          class_time_id: class_time_ids[i],
          class_time_name: class_time_names[i],
          class_time_week: class_time_weeks[i],
          class_time: class_times[i]
        });
      });
    }
    return result;
  }
  public prepareMealTimeData(item: any): any {
    let result = [];
    if (item.meal_time_ids != null) {
      let meal_time_ids = item.meal_time_ids.split(",");
      let meal_time_names = item.meal_time_names.split(",");
      let meal_time_weeks = item.meal_time_weeks.split(",");
      let meal_times = item.meal_times.split(",");
      meal_time_ids.forEach((element, i) => {
        result.push({
          meal_time_id: meal_time_ids[i],
          meal_time_name: meal_time_names[i],
          meal_time_week: meal_time_weeks[i],
          meal_time: meal_times[i]
        });
      });
    }
    return result;
  }



  public getStatusCheckinReport(cate_checkin_time: string, checkin_times): number {
    // cate_checkin_time: "11:22-14:44-10-10, checkin_times : ['12:23','44:55']
    // Chuyển đổi giờ bắt đầu và giờ kết thúc thành đối tượng Date
    const temps = cate_checkin_time.split("-");

    const time_start = this.timeStrToDate(temps[0]); // giờ bắt đầu
    const time_end = this.timeStrToDate(temps[1]); // giờ kết thúc
    const early = parseInt(temps[2]);// số phút tính sớm
    const late = parseInt(temps[3]);// số phút tính muộn
    const effective = parseInt(temps[4]); // số phút bắt đầu tính
    const weeks = temps[5].split("w"); // danh sách các ngày hiệu lực
    var time_effective = this.timeStrToDate(temps[0]); // giờ bắt đầu
    time_effective.setMinutes(time_effective.getMinutes() - effective);
    console.log("-------------^^^^^^^--------------");
    // console.log('time_start',time_start);
    // console.log('effective',effective);
    // console.log('time_effective',time_effective);

    // Lặp qua các giờ điểm danh và kiểm tra xem chúng có thuộc khoảng thời gian làm việc hay không
    var isOnTime = false;
    var isLate = false;
    for (const checkInTime of checkin_times) {
      var time_checkIn = this.timeStrToDate(checkInTime);
      var time_checkIn_week = time_checkIn.getDay();
      // console.log("time_checkIn:", time_checkIn);
      // console.log("time_checkIn_week:", time_checkIn_week);
      time_start.setMinutes(time_start.getMinutes() + late);
      // console.log("checkIn:", time_checkIn);
      // Nếu giờ điểm danh nằm ngoài khoảng thời gian làm việc thì trả về true (nhân viên đi làm muộn)

      if (time_effective <= time_checkIn && time_checkIn <= time_start) {
        if (!isOnTime) { // chỉ được thay đổi 1 lần
          isOnTime = true;
        }
        // break;
      } else if (time_start < time_checkIn && time_checkIn < time_end) {
        if (!isLate) { // chỉ được thay đổi 1 lần
          isLate = true;
          console.log("222isLate:", isLate);
        }
      }
    }



    console.log("isOnTime:", isOnTime);
    console.log("isLate:", isLate);
    console.log("status: ", isOnTime ? 1 : isLate ? 2 : 3);
    return isOnTime ? 1 : isLate ? 2 : 3;
  }
  private timeStrToDate(timeStr: string) {
    const temps = timeStr.split(":");
    const date = new Date();
    date.setHours(parseInt(temps[0]), parseInt(temps[1]), 0);
    return date;
  }
  public dayStrToMonthFull(timeStr: string) {
    const temps = timeStr.split("/");
    return "01/" + temps[1] + "/" + temps[2];
  }

  public dayStrToMonth(timeStr: string) {
    const temps = timeStr.split("/");
    if (temps.length == 3)
      return temps[1] + "/" + temps[2];
    return timeStr;
  }
  public dateStrToMonthStr(dateStr: string) {
    console.log(dateStr);
    const temps = dateStr.split("-");
    if (temps.length == 1) // khonog cos
      return dateStr;
    return temps[1] + "/" + temps[0];
  }
  public dateStrToDate(dateStr: String): Date {
    var date_temp = new Date();
    var temps = dateStr.split("/");
    if (temps.length == 3) {
      var dt = parseInt(temps[0]);
      var mon = parseInt(temps[1]);
      var yr = parseInt(temps[2]);
      date_temp = new Date(yr, mon - 1, dt);
    }
    return date_temp;
  }

  public getDateFromDDMMYYY(dateStr: string): Date {
    var dateParts = dateStr.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  }


  public getFullDateStrFromNgbDate(date: any): string {
    let day = date.day < 10 ? "0" + date.day : date.day;
    let month = date.month < 10 ? "0" + date.month : date.month;

    return day + "/" + month + "/" + date.year;
  }
  public getFullDateStrFromDate(date: Date): string {
    if (typeof date == 'undefined' || date == null) return '';
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return day + "/" + month + "/" + date.getFullYear();
  }
  public getMonthStrFromDate(date: Date): string {
    if (typeof date == 'undefined' || date == null) return '';
    // let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return month + "/" + date.getFullYear();
  }
  public getFullDate2StrFromDate(date: Date): string {
    if (typeof date == 'undefined' || date == null) return '';
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return date.getFullYear() + "-" + month + "-" + day;
  }
  public getDDMMStrFromDate(date: Date): string {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return day + "/" + month;
  }
  public getDayOfWeekFromDate(date: Date): string {
    let day = date.getDay();
    if (day == 1) return "Thứ 2";
    else if (day == 2) return "Thứ 3";
    else if (day == 3) return "Thứ 4";
    else if (day == 4) return "Thứ 5";
    else if (day == 5) return "Thứ 6";
    else if (day == 6) return "Thứ 7";
    else if (day == 0) return "Chủ nhật";
    else return "Không rõ";
  }
  public getFullDateTimeStrFromDate(date: Date): string {
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let month_temp = date.getMonth() + 1;
    let month = month_temp < 10 ? "0" + month_temp : month_temp;
    return "" + date.getHours() + ":" + date.getMinutes() + ":" + " " + day + "/" + month + "/" + date.getFullYear();
  }
  public getDateTimeFromDate(dateStr: String): string {
    let date = new Date();
    let temp = dateStr.split("/");

    return temp[2] + "-" + temp[1] + "-" + temp[0] + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  public getFullDatetimeStr(): string {
    let date = new Date();
    // return date.toString();
    return date.getHours() + ":" + date.getMinutes() + " " + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  }
  public getDayMonthStrFromDate(date: Date): string {
    return date.getDate() + "/" + (date.getMonth() + 1);
  }
  checkToAddCart(list: [any], cart: any) {
    let isUpdateQantity = false;
    for (var i = 0; i < list.length; i++) {
      if (list[i].product.id == cart.product.id) {
        list[i].quantity = list[i].quantity + cart.quantity;
        isUpdateQantity = true;
        break;
      }
    }
    return isUpdateQantity;
  }
  checkExistCart(list: [any], cart: any) {
    let isExisted = false;
    for (var i = 0; i < list.length; i++) {
      if (list[i].product.id == cart.product.id) {
        isExisted = true;
        break;
      }
    }
    return isExisted;
  }
  getDataFromList(list: [any], subject: string): string {
    // list.forEach(element => {
    //    var temp = element.split('_'); // "I_hoa_7.2_" = hocky_môn_tbm_tbmcn
    //   //  console.log(temp);
    //    var code = temp[1]; // môn
    //    if(code == subject){
    //       console.warn(subject,temp[2] );
    //       return temp[2] // tbm
    //    }
    // });
    // return '';

    let isExisted = '';
    for (var i = 0; i < list.length; i++) {
      var temp = list[i].split('='); // "I_hoa_7.2_" = hocky_môn_tbm_tbmcn
      var code = temp[1]; // môn

      if (code == subject) {
        isExisted = temp[2];
        break;
      }
    }
    return isExisted;

  }
  checkToAddCartService(list: [any], cartService: any) {
    console.log(list);
    console.log(cartService);
    let isExisted = false;

    var ii = list;

    for (var i = 0; i < list.length; i++) {
      if (i < list.length - 1) {
        list[i].isEnable = 0;
      }
      else {
        if (list[i].id == cartService.id) {
          list[i].isEnable = 1;
          isExisted = true;
        } else {
          list[i].isEnable = 0;
        }
      }
    }
    console.log("isExisted", isExisted);
    return isExisted;
  }

  checkOutService(type) {
    if (type == "reset") {
      this.appService.deskList[this.appService.deskIndex].service_price = 0;
      this.appService.deskList[this.appService.deskIndex].price_totol -= this.appService.deskList[this.appService.deskIndex].service_price_temp;
      this.appService.deskList[this.appService.deskIndex].service_price = 0;
    } else {
      let service_price_temp = 0
      for (var i = 0; i < this.appService.deskList[this.appService.deskIndex].cart_service.length; i++) {
        service_price_temp += this.appService.deskList[this.appService.deskIndex].cart_service[i].amount;
      }

      this.appService.deskList[this.appService.deskIndex].service_price = service_price_temp;
      this.appService.deskList[this.appService.deskIndex].price_totol += this.appService.deskList[this.appService.deskIndex].service_price;
      this.appService.deskList[this.appService.deskIndex].service_price_temp = service_price_temp;
    }
    // cộng giá dịch vụ vào tổng giá của hàng hóa
    this.appService.deskList[this.appService.deskIndex].customer_pay = this.appService.deskList[this.appService.deskIndex].price_totol;
    // tính toán lại khuyến mãi
    this.appService.preparePromotion(this.appService.deskList[this.appService.deskIndex]);
  }
  removeVietnameseTones(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.replace(' ', "_");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, "_");
    return str;
  }

  changeDeskService(indexFrom, indexTo): boolean {

    try {
      console.log("indexFrom", this.appService.deskList[indexFrom]);
      this.appService.deskList[indexTo].cart = this.appService.deskList[indexFrom].cart
      this.appService.deskList[indexTo].cart_service = this.appService.deskList[indexFrom].cart_service
      this.appService.deskList[indexTo].customer_code = this.appService.deskList[indexFrom].customer_code
      this.appService.deskList[indexTo].customer_id = this.appService.deskList[indexFrom].customer_id
      this.appService.deskList[indexTo].customer_name = this.appService.deskList[indexFrom].customer_name
      this.appService.deskList[indexTo].customer_pay = this.appService.deskList[indexFrom].customer_pay
      this.appService.deskList[indexTo].payment_total = this.appService.deskList[indexFrom].payment_total
      this.appService.deskList[indexTo].price = this.appService.deskList[indexFrom].price
      this.appService.deskList[indexTo].price_after_promotion = this.appService.deskList[indexFrom].price_after_promotion
      this.appService.deskList[indexTo].price_promotion = this.appService.deskList[indexFrom].price_promotion
      this.appService.deskList[indexTo].price_totol = this.appService.deskList[indexFrom].price_totol
      this.appService.deskList[indexTo].product_quantity = this.appService.deskList[indexFrom].product_quantity
      this.appService.deskList[indexTo].promotion_promotion = this.appService.deskList[indexFrom].promotion_promotion
      this.appService.deskList[indexTo].promotion_type = this.appService.deskList[indexFrom].promotion_type
      this.appService.deskList[indexTo].quantity = this.appService.deskList[indexFrom].quantity
      this.appService.deskList[indexTo].service_detail_str = this.appService.deskList[indexFrom].service_detail_str
      this.appService.deskList[indexTo].service_details = this.appService.deskList[indexFrom].service_details
      this.appService.deskList[indexTo].service_price = this.appService.deskList[indexFrom].service_price
      this.appService.deskList[indexTo].service_price_temp = this.appService.deskList[indexFrom].service_price_temp
      this.appService.deskList[indexTo].status = this.appService.deskList[indexFrom].status
      this.appService.deskList[indexTo].total_quantity = this.appService.deskList[indexFrom].total_quantity
      this.appService.deskList[indexTo].type = this.appService.deskList[indexFrom].type
      this.appService.deskList[indexTo].user_id = this.appService.deskList[indexFrom].user_id

      this.appService.deskList[indexFrom].cart = new Array();
      this.appService.deskList[indexFrom].cart_service = [];
      this.appService.deskList[indexFrom].customer_code = "";
      this.appService.deskList[indexFrom].customer_id = 0
      this.appService.deskList[indexFrom].customer_name = "Khách lẻ"
      this.appService.deskList[indexFrom].customer_pay = 0
      this.appService.deskList[indexFrom].payment_total = 0
      this.appService.deskList[indexFrom].price = 0
      this.appService.deskList[indexFrom].price_after_promotion = 0
      this.appService.deskList[indexFrom].price_promotion = 0
      this.appService.deskList[indexFrom].price_totol = 0
      this.appService.deskList[indexFrom].product_quantity = 0
      this.appService.deskList[indexFrom].promotion_promotion = 0
      this.appService.deskList[indexFrom].promotion_type = "%"
      this.appService.deskList[indexFrom].quantity = 0
      this.appService.deskList[indexFrom].service_detail_str = ""
      this.appService.deskList[indexFrom].service_details = []
      this.appService.deskList[indexFrom].service_price = 0
      this.appService.deskList[indexFrom].status = 0
      this.appService.deskList[indexFrom].total_quantity = 0
      this.appService.deskList[indexFrom].type = 0
      this.appService.deskList[indexFrom].user_id = 0
      localStorage.setItem(environment.deskList, JSON.stringify(this.appService.deskList));
      console.log("indexTo", this.appService.deskList[indexTo]);
      return true
    } catch (error) {
      console.error("error", error);
      return false
    }
  }

  getCurrentTimeService() {
    var today = new Date();
    today.setSeconds(0);

    // let i_index = 1;
    let i_id = 1;
    for (var i = 0; i < this.appService.deskListPrice.length; i++) {
      var hourS_temp = this.appService.deskListPrice[i].timeStart.split(":"); // tách hh:mm
      var hourE_temp = this.appService.deskListPrice[i].timeEnd.split(":"); // tách hh:mm
      var start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hourS_temp[0], hourS_temp[1], 0);
      var end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hourE_temp[0], hourE_temp[1], 0);

      if (start < today && today < end) {
        console.log("start", start);
        console.log("end", end);
        // i_index = i;
        i_id = this.appService.deskListPrice[i].id;
        break;
      }
    }

    return i_id;
  }


  public checkUnit(unit: string) {
    let isExist = false;

    this.appService.units.forEach(function (item) {
      if (item.value == unit) {
        isExist = true;
        return isExist;
      }
    });
    return isExist;
  }
  getNameOfRoleUser(role: string) {
    if (role == "admin") return "Admin";
    for (var i = 0; i < this.appService.memberships.length; i++) {
      if (this.appService.memberships[i].code == role) {
        return this.appService.memberships[i].name;
      }
    }
    return "Unknow";
  }
  getNameByCode(code, data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].code == code) {
        return data[i].value;
      }
    }
    return "Unknow";
  }
  searchProduct(searchValue: string) {
    console.log("searchValue", searchValue);
    console.log("products", this.appService.products_backup);

    for (var i = 0; i < this.appService.products_backup.length; i++) {

      if (this.appService.products_backup[i].code == searchValue) {
        return this.appService.products_backup[i];
      }
    }
    return null;
  }

  public get7days(dateSelect: Date): any {
    let temp = dateSelect;
    let result = [];
    for (var i = 0; i < 7; i++) {
      result[i] = {
        date1: this.getFullDateStrFromDate(temp),
        date2: this.getDayMonthStrFromDate(temp)
      };
      temp = new Date(temp.getTime() - 86400000)
    }
    return result;
  }
  public formatDateStr(date): any {
    let temp = date.split("-");
    return temp[2] + "/" + temp[1] + "/" + temp[0];
  }

  public getExtensionFile(file): string {
    if (file !== null && file !== undefined) {
      let name = file.name.split(".");
      return name[name.length - 1];
    } else return "";
  }
  public formatDateJsonToStr(date): any {
    if (date !== null) {
      return date.day + "/" + date.month + "/" + date.year;
    }
    return "";
  }
  public formatDateToStr(date): any {
    // Kiểm tra nếu data là chuỗi
    if (typeof date === 'string') {
      date = new Date(date);
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  public isValidDate(dateString) {
    if(dateString == null) return false;
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  } 

  public toFixedIfNecessary(value) {
    return +parseFloat(value).toFixed(2);
  }
  /////////////////
  public convertViToEn(str, toUpperCase = false) {
    if (str == null) return "";
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    // str = str.split(' ').join('_');
    str = str.split(' ').join('');// bỏ dấu cách
    return toUpperCase ? str.toUpperCase() : str;
  }

  public mathRound(value): number {
    return Math.round(value);
  }
  public checkExistSubjects(code, data,): boolean {
    var temp = false;
    if (data != null) {
      data.forEach(element => {
        if (element.subjects_code == code) temp = true;
      });
    }
    return temp;
  }
  public checkExistClass(code, data,): boolean {
    var temp = false;
    if (data != null) {
      data.forEach(element => {
        if (element.class_code == code) temp = true;
      });
    }
    return temp;
  }
  public checkAndGetKhoi_code(name, data): string {
    var khoi_code = '';
    if (data != null) {
      data.forEach(element => {
        if (element.value == name) khoi_code = element.code;
      });
    }
    return khoi_code;
  }
  public checkAndGetKhoi_name(code, data): string {
    var khoi_name = '';
    if (data != null) {
      data.forEach(element => {
        if (element.code == code) khoi_name = element.value;
      });
    }
    return khoi_name;
  }
  public checkAndGetTx_num(subjects_code, settings, tx_num): number {
    // var temp = khois.tx_num;
    if (settings != null) {
      settings.forEach(element => {
        if (element.subjects_code == subjects_code) tx_num = element.tx_num;
      });
    }
    return tx_num;
  }
  public checkAndGetTx_numSetting(khoi_code, subjects_code, dateSettings): number {
    var tx_num = this.appService.tx_num_default;
    if (dateSettings != null) {
      dateSettings.forEach(element => {
        if (element.khoi == khoi_code && element.subjects_code == subjects_code) tx_num = element.tx_num;
      });
    }
    return tx_num;
  }
  public checkAndGetisLockSetting(khoi_code, subjects_code, dateSettings): number {
    var isLock = 0;
    if (dateSettings != null) {
      dateSettings.forEach(element => {
        if (element.khoi == khoi_code && element.subjects_code == subjects_code) isLock = element.isLock;
      });
    }
    return isLock;
  }
  public checkAndGetIDSetting(khoi_code, subjects_code, dateSettings): number {
    var id = 0;
    if (dateSettings != null) {
      dateSettings.forEach(element => {
        if (element.khoi == khoi_code && element.subjects_code == subjects_code) id = element.id;
      });
    }
    return id;
  }
  public checkAndGetMark(mark_column, markObjects): any {
    var temp = "";
    if (markObjects != null) {
      markObjects.forEach(element => {
        if (element.mark_column == mark_column) temp = element.mark;
      });
    }
    return temp;
  }
  public checkAndGetMark2(mark_column, markObjects): any {
    console.log(mark_column, markObjects);
    var temp = "";
    if (markObjects != null) {
      for (var key of Object.keys(markObjects)) {
        if (key == mark_column) temp = (markObjects[key] != '0') ? markObjects[key] : "";
      }
    }
    return temp;
  }

  public formatTeachingAssignmentJsonToStr(data): string {
    var temp = "";
    if (typeof data != 'undefined' && data != "") {
      data.forEach(element => {
        temp += element.subjects_name;
        temp += "(";

        for (let i = 0; i < element.class.length; i++) {
          if (element.class[i].actions) {
            temp += element.class[i].class_name + ",";
          }
        }

        if (element.class.length > 0) {
          temp = temp.slice(0, -1);
        }

        temp += "); ";
      });

    }
    return temp;
  }
  public validMark(mark): any {
    if (mark == null || mark == '') return '';

    if (isNaN(mark)) {
      mark = mark.replace(",", ".");
      mark = Number(mark);
    }
    if (isNaN(mark)) {
      mark = ''; //nếu không phải số thì gán null
      return '';
    }

    if (0 <= mark && mark <= 10) {
      //  hợp lệ
    } else {
      mark = '';
    }
    return mark;
  }
  public validMarkNhanXet(mark): any {
    if (mark == null || mark == '') return '';
    console.log("Asdf asdf d");
    mark = mark.toUpperCase();
    if (mark == 'D' || mark == 'Đ') {
      mark = 'Đ';
    } else if (mark == 'CD' || mark == 'CĐ') {
      mark = 'CĐ';
    } else {
      mark = '';
    }
    return mark;
  }
  public formatCurrency(number): any {
    // Định dạng số với dấu chấm phân cách hàng nghìn và dấu chấm thập phân
    let formattedNumber = Math.floor(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    
    // Thêm đơn vị tiền tệ nếu cần
    // formattedNumber = '$' + formattedNumber;

    return formattedNumber;
}
  public getMonthListInRange(): any {
    var startDateStr, endDateStr;
    console.log(startDateStr, endDateStr);

    console.log(this.appService.currentUser.scholastics_list);
    // lấy thời gian năm học scholastics
    this.appService.currentUser.scholastics_list.forEach(element => {
        if(element.code == this.appService.scholasticSelected){
          startDateStr = element.hockyI_startTime;
          endDateStr = element.hockyII_endTime;
        }
    });
    
    console.log(startDateStr, endDateStr);
    // Định dạng yyyy-mm-dd
    let months = [];
    let today2 = new Date();
    let today = new Date();
    today.setDate(1); // đặt lại ngày 1
    try {
        if (startDateStr !== undefined && endDateStr !== undefined) {
            let endDate_temp = new Date(endDateStr.split('/').reverse().join('/')); // Ngày kết thúc
            endDate_temp.setDate(1); // đặt lại ngày 1

            let endDate; // Ngày kết thúc
            if (endDate_temp > today) { // kiểm tra nếu lớn hơn ngày hiện tại thì giới hạn về tháng hiện tại  + 1
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            } else {
                endDate = endDate_temp;
            }

            let currentDate = new Date(startDateStr.split('/').reverse().join('/'));
            while (currentDate <= endDate) {
                // Lấy tháng hiện tại và thêm vào mảng
                months.push({
                    month_full: "01/" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' + currentDate.getFullYear(),
                    month: (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' + currentDate.getFullYear(),
                    month_mm: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
                    month_yyyy: currentDate.getFullYear().toString(),
                    selected: (today2.getMonth() === currentDate.getMonth() && today2.getFullYear() === currentDate.getFullYear())
                });
                // Tăng ngày lên 1 tháng
                currentDate.setMonth(currentDate.getMonth() + 1);
            }
        }
    } catch (error) {
        console.log("Lỗi khi lấy danh sách tháng Meal", error);
    }

    return months;
  }

}
