
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppService } from 'src/app/_services/app.service';
import { Category } from 'src/app/model/category';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { MatDialog } from '@angular/material/dialog';
import { TeacherPickerComponent } from 'src/app/components/teacher-picker/teacher-picker.component';
import { HolidayPickerComponent } from 'src/app/components/holiday-picker/holiday-picker.component';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-category-checkin-time',
  templateUrl: './category-checkin-time.component.html',
  styleUrls: ['./category-checkin-time.component.css']
})
export class CategoryCheckinTimeComponent implements OnInit {
  @ViewChild('addForm') addForm: HTMLFormElement;
  @ViewChild('updateForm') updateForm: HTMLFormElement;

  isLoading = false;
  formGroup: FormGroup;
  // for create
  CodeType = 'tn';  //mts
  CodeTemp = "";
  file: File = null;
  imageUrl: any = environment.product_avatar;

  categorys: any;
  categorys_backup: any;

  cateType = environment.cateType_checkin_time;
  // for add
  cate = new Category(environment.cateType_checkin_time);
  // for update
  categoryselected: any;
  categoryselected_file: File = null;
  categoryselected_imageUrl: any = environment.product_avatar;
  isDeleted: boolean;
  temp_weeks_update = []; //clone
  //for paging
  viewNumsObj = this.appService.viewNums[1].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];
  //for search
  searching = false;

  duration = '';

  sorter = {
    sortStr: 'checkin_start ASC',
  }

  filter = {
    name: "",
    deleted: 0,
    type: this.cateType
  }



  constructor(
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    public appService: AppService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Lịch dạy / làm việc";
    this.categoryselected = this.cate;
    // this.countAllCategory();
  }

  ngOnInit() {
    this.loadCategory();
    this.formGroup = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ])
    });
  }
  onSubmit() {
    console.log(this.formGroup);
  }

  onReset() {
    this.formGroup.reset();
  }
  ngAfterViewInit() {
    // $("#form-add-category").validate({
    //   rules: {
    //     name: {
    //       // minlength: 2,
    //       required: !0
    //     },
    //   },
    //   errorClass: "help-block error",
    //   highlight: function (e) {
    //     $(e).closest(".form-group.row").addClass("has-error")
    //   },
    //   unhighlight: function (e) {
    //     $(e).closest(".form-group.row").removeClass("has-error")
    //   },
    // });
  }
  clearSearch() {
    this.filter.name = '';
    this.loadCategory();
  }
  changeCheckbox(event) {
    console.log(event);
    console.log(this.appService.weeks);
  }
  checkinChanged(event){
    this.duration = this.utility.calculateDuration(this.cate.checkin_start, this.cate.checkin_end);
  }
  resetCategory() {
    this.cate = new Category(this.cateType);
    this.file == null;
    this.imageUrl = environment.product_avatar;
  }
  category_change(event) {
    this.loadCategory();
  }
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };

    this.categoryeduService.loadCategory(this.filter, this.sorter, pagingObj).subscribe(
      async response => {
        if (response) {

          response.data.forEach(element => {
            element.checkin_weeks_name =  this.utility.getWeekNameByWeekCode(element.checkin_weeks);
          });

          this.categorys = response.data;
          this.categorys_backup = response.data;

          this.totalItems = response.totalItems;
          this.totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.listPage.push({ i: i });
          }
          this.searching = false;

          console.log(this.categorys);
        }
      },
      error => {
        console.error(error);
      })
  }
  insertCategory() {
    console.log(this.cate);
    if (this.cate.name.trim() == "") {
      this.toastr.warning("", "Vui lòng tên ");
      return false;
    }

    // kiểm tra ngày có vượt quá 1 năm hay không.
    if(this.utility.getDayDiff(this.cate.hockyI_startTime,this.cate.hockyI_endTime) > 183){
      this.toastr.warning("", "Thời gian không được vượt quá 6 tháng");
      return false;
    }// kiểm tra giờ có âm hay không
    if(this.utility.getTimeDiff(this.cate.checkin_start,this.cate.checkin_end) <= 0){
      this.toastr.warning("", "Thời gian không hợp lệ");
      return false;
    }

    this.cate.name = this.cate.name.trim();
    this.cate.code = 'CT' + Math.floor(Math.random()*10000);

    if (this.file !== null) {
      this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
    }
    //xử lý danh sách ngày 
    var temp_weeks = [];
    this.appService.weeks.forEach(element => {
        if(element.checked){
          temp_weeks.push(element.code);
        }
    });
    this.cate.checkin_weeks = temp_weeks.join("w");

    this.cate.hockyI_startTime = this.utility.formatDateToStr(this.cate.hockyI_startTime);
    this.cate.hockyI_endTime = this.utility.formatDateToStr(this.cate.hockyI_endTime);
    
    if (!this.addForm.invalid) {
      this.isLoading = true;
      this.categoryeduService.insertCategory(this.cate).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response) {
            this.toastr.success("", "Thêm mới danh mục thành công");
            $("#add-category-modal").modal('hide');
            this.resetCategory();
            this.loadCategory();
          }
          else {
            this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
          }
        },
        error => {
          this.isLoading = false;
          this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
          console.error(error);
        })
    }
  }

  countAllCategory() {
    this.categoryeduService.countAllCategory(this.cateType).subscribe(
      response => {
        this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
        // console.log("this.cate.code",this.cate.code);
      },
      error => {

        console.error(error);
      })

  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.categorys = this.categorys_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();
    console.log(ev.target.value.trim());
    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.categorys = this.categorys.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  popup_category_add() {

    this.resetCategory();
    this.countAllCategory();
    $("#add-category-modal").modal('show');

  }

  
  popupUpdate(item) {
    this.categoryselected = item;
    this.categoryselected_imageUrl = this.appService.showImageProduct(item.image_url);
    this.isDeleted = this.categoryselected.deleted == 0 ? false : true;

    // Xử lý danh sách week
    this.temp_weeks_update = [];
    var temp_weeks = this.categoryselected.checkin_weeks.split("w");
    this.appService.weeks.forEach((element,i) => {
        this.temp_weeks_update.push({ 'code': element.code, 'value': element.value,'checked':false },);//clone

        temp_weeks.forEach(element_w => {
          if(element_w == element.code){
            this.temp_weeks_update[i].checked = true;//clone and false default
          }
       });
    });

    console.log(this.categoryselected);
    console.log(this.temp_weeks_update);

    $("#update-category-modal").modal('show');
  }
  openHolidayPicker(item){
    if(item != null){
      this.categoryselected = item;
    }
    let data = [];
    data.push({
      code : this.categoryselected.code,
      holiday_ids : this.categoryselected.holiday_ids
    });

    console.log(data);
 
    // mở popup
    const dialogRef = this.dialog.open(HolidayPickerComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {title:"", data: data}  
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.length > 0) {
        console.log("class_code", result);

        let param = {
          type: environment.cateType_checkin_time_holiday,
          obj_codes: data,
          obj_id2s: result
        }
        this.categoryeduService.saveMultiCategorys(param).subscribe(
          async response => {
            console.log(response);
            if (response.code == "ok" && response.affectedRows > 0) {
              this.toastr.success("","Cài đặt ngày nghỉ thành công");
              $("#update-category-modal").modal('hide');
              this.loadCategory();
            }else{
              this.toastr.warning("","Có lỗi xảy ra");
            }
          },
          error => {
            console.error("error:");
            console.error(error);
          })


        
      }
    });
  }
  updateCategory() {
    if (this.categoryselected.name.trim() == "") {
      this.toastr.warning("", "Vui lòng nhập tên");
      return false;
    }

    if(!this.utility.isValidDate(this.categoryselected.hockyI_startTime) || !this.utility.isValidDate(this.categoryselected.hockyI_endTime)){
      this.toastr.warning("", "Thời gian không hợp lệ");
      return false;
    }
   
    
    // kiểm tra ngày có vượt quá 1 năm hay không.
    if(this.utility.getDayDiff(this.categoryselected.hockyI_startTime,this.categoryselected.hockyI_endTime) > 365){
      this.toastr.warning("", "Thời gian không được vượt quá 1 năm");
      return false;
    }


    if (this.categoryselected_file !== null) {
      this.categoryselected.image_url = this.cateType + "/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
      console.log("1111");
    }

    this.categoryselected.deleted = this.isDeleted ? 1 : 0;
    console.log(this.categoryselected);

    //xử lý danh sách ngày 
    var temp_weeks = [];
    this.temp_weeks_update.forEach(element => {
        if(element.checked){
          temp_weeks.push(element.code);
        }
    });
    this.categoryselected.checkin_weeks = temp_weeks.join("w");

    
    this.categoryselected.hockyI_startTime = this.utility.formatDateToStr(this.categoryselected.hockyI_startTime);
    this.categoryselected.hockyI_endTime = this.utility.formatDateToStr(this.categoryselected.hockyI_endTime);

    this.isLoading = true;
    var data = {
      id: this.categoryselected.id,
      name: this.categoryselected.name,
      checkin_start: this.categoryselected.checkin_start,
      checkin_end: this.categoryselected.checkin_end,
      checkin_early: this.categoryselected.checkin_early,
      checkin_late: this.categoryselected.checkin_late,
      checkin_effective: this.categoryselected.checkin_effective,
      checkin_weeks: this.categoryselected.checkin_weeks,
      hockyI_startTime: this.categoryselected.hockyI_startTime,
      hockyI_endTime: this.categoryselected.hockyI_endTime,
      deleted: this.categoryselected.deleted,
    }
    this.categoryeduService.updateByID(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == "ok") {
          this.loadCategory();
          $("#update-category-modal").modal('hide');
          this.toastr.success("", "Cập nhật danh mục thành công");

        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  async changeListener($event): Promise<void> {
    this.file = $event.target.files[0];
    if (this.file == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
    console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
        this.file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.imageUrl = reader.result;
          console.log(this.imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }
  async changeImageUpdate($event): Promise<void> {
    this.categoryselected_file = $event.target.files[0];
    if (this.categoryselected_file == null) {
      return;
    }

    try {
      this.ng2ImgMax.resizeImage(this.categoryselected_file, 300, 300).subscribe(result => {
        this.categoryselected_file = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.categoryselected_file);
        reader.onload = (_event) => {
          this.categoryselected_imageUrl = reader.result;
          console.log(this.categoryselected_imageUrl);
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }

  codeTypeClick(type) {
    this.CodeType = type;
    if (this.CodeType == "mts") {
      this.cate.code = this.CodeTemp;
    } else if (this.CodeType == "tn") {
      this.cate.code = "";
    }
  }
  focusOutCheckCode() {
    let filter = {
      code: this.cate.code,
      type: this.cateType
    }
    this.categoryeduService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if (response.code == 0) {
            this.addForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code", "Lỗi");
            console.log(this.addForm.controls.code.errors);
            console.log("kkkkk", this.addForm.controls.code.errors);
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadCategory();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadCategory();
    }
  };

  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadCategory();
      }),
      map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r);
  }

  /////////////////
  openSelectTeacher(classSelected, index) {
    // this.appService.deskIndex = index;
    // this.modalService.open(content, { size: 'xl' });

    console.log("this.appService.deskIndex", this.appService.deskList[this.appService.deskIndex]);
    this.appService.deskIndex = index;
    const dialogRef = this.dialog.open(TeacherPickerComponent, {
      position: {
        // left: '10px',
        // right: '10px'
      },
      width: '95%',
      height: '95%',
      panelClass: 'full-screen-modal',
      data: { deskIndex: index, classSelected: classSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("close");
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result.data", result.data);
        // load lại dữ liệu
        this.loadCategory();
      }
    });
  }

}
