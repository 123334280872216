<form class="">
    <div class="p-2">
        <div class="modal-header p-2">
            <h5 class="modal-title">{{type=='edit'?'Cập nhật lịch dạy': 'Copy lịch dạy'}}</h5>
        </div>
        <div class="modal-body p-2 mt-3 mb-3">
            <div class="row">
                <div class="col">

                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Ngày</mat-label>
                        <input matInput [matDatepicker]="picker_u_startTime" placeholder="DD/MM/YYYY" [(ngModel)]="checkin_date" name="u_startTime" required>
                        <mat-datepicker-toggle matSuffix [for]="picker_u_startTime"></mat-datepicker-toggle>
                        <mat-datepicker #picker_u_startTime></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>Giáo viên</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="item.teacher_code" name="teacher_code">
                            <mat-option *ngFor="let item of teacher_list" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>Lớp dạy</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="item.class_code" name="class_code">
                            <mat-option *ngFor="let item of class_list" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill" class="mr-3">
                        <mat-label>Môn dạy</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="item.subject_code" name="subject_code">
                            <mat-option *ngFor="let item of subject_list" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Giờ bắt đầu</mat-label>
                        <input matInput type="time" [(ngModel)]="item.checkin_start" placeholder="Giờ vào" name="checkin_start" (ngModelChange)="checkinChanged($event)" required minlength="5" maxlength="5">
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Giờ kết thúc</mat-label>
                        <input matInput type="time" [(ngModel)]="item.checkin_end" placeholder="Giờ ra" name="checkin_end" (ngModelChange)="checkinChanged($event)" required minlength="5" maxlength="5">
                    </mat-form-field>
                </div>
                <div class="ml-1" style="width: 100px;">
                    <mat-form-field class="mb-3" appearance="fill">
                        <mat-label>Thời lượng</mat-label>
                        <input matInput type="text" [(ngModel)]="duration" name="thoiluong" readonly>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer justify-content-around bg-primary-50">
        <button (click)="okClick()" class="btn btn-primary ">
            {{type=='edit'?'Cập nhật': 'Copy'}}
        </button>
        <button (click)="cancelClick()" class="btn">
            Bỏ qua
        </button>
    </div>
</form>