import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from 'src/app/_services/utility.service';
import { PopupGenSchdeduleDetailComponent } from '../popup-gen-schdedule-detail/popup-gen-schdedule-detail.component';
import { TeacherService } from 'src/app/_services/teacher.service';
import { environment } from 'src/environments/environment';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popup-edit-schedule-detail',
  templateUrl: './popup-edit-schedule-detail.component.html',
  styleUrls: ['./popup-edit-schedule-detail.component.css'],
})
export class PopupEditScheduleDetailComponent implements OnInit {
  item : any;
  type = '';
  duration = '';
  teacher_list = [];
  checkin_date : any;

  class_list = [];
  subject_list = [];
  constructor(
    public categoryeduService: CategoryEduService,
    private teacherService: TeacherService,
    private utility: UtilityService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<PopupEditScheduleDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.type = data.type;
    this.item = data.data;
    this.checkin_date = utility.dateStrToDate(this.item.checkin_date);
    console.log(this.type);
    console.log(this.item);
    this.duration = this.utility.calculateDuration(this.item.checkin_start, this.item.checkin_end);
    this.loadTeacher();
  }

  ngOnInit(): void {
    this.loadClass();
    this.loadSubject();
  }
  checkinChanged(event){
    this.duration = this.utility.calculateDuration(this.item.checkin_start, this.item.checkin_end);
  }
  loadTeacher() {
    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1,
    };
    let filter = {};
    let sorter = {
      sortStr: 'name ASC',
    };
    this.teacherService.loadTeacher(filter, sorter, pagingObj).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.teacher_list = response.data;
          // $('#category').selectpicker();
        }
      },
      (error) => {
        // this.isLoading.dismiss();
        console.error(error);
      }
    );
  }
  loadClass() {
    let filter = {
      type: environment.cateType_class,
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.class_list = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  loadSubject() {
    let filter = {
      type: environment.cateType_subjects,
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async (response) => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.subject_list = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  okClick(){
    
    let data = {
      id : this.item.id,
      code_parent : this.item.ass_code,
      checkin_date : this.utility.formatDateToStr(this.checkin_date),
      checkin_start : this.item.checkin_start,
      checkin_end : this.item.checkin_end,
      teacher_code : this.item.teacher_code,
      class_code : this.item.class_code,
      subject_code : this.item.subject_code,
      type : this.type,
    }

    this.teacherService.updateSomeScheduleDetailByID(data).subscribe(
      async (response) => {
        console.log(response);
        if (response.code == 'ok') {
          this.toastr.success( 'Cập nhật/Copy lịch dạy thành công','Thành công');
          this.dialogRef.close({code: 'ok', msg: ''});
        }else{
          this.toastr.warning(response.msg,'Có lỗi xảy ra');
          console.error("có lỗi xảy ra");
        }
      },
      (error) => {
        console.error('error:');
        console.error(error);
      }
    );
  
    
  }
  cancelClick() {
    this.dialogRef.close();
  }
}
